/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';

// Breakpoints
const mediaQueryLaptop = window.matchMedia('(min-width: 1024px)');

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--vh', `${document.documentElement.clientHeight}px`);
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal], .lg-modal',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1920px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal], .lg-modal');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Slider block
var sliders = document.querySelectorAll('.content-block.is-slider-block');
if (sliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		sliders.forEach(el => {
			if (el.querySelectorAll('.column').length > 1) {
				const columnBlock = el.querySelector('.columns'),
					swiperItem = columnBlock.querySelectorAll('.column'),
					swiperContainer = document.createElement('div'),
					swiperButtonPrev = document.createElement('div'),
					swiperButtonNext = document.createElement('div');

				swiperContainer.setAttribute('class', 'swiper-container image-slider');
				columnBlock.setAttribute('class', 'swiper-wrapper');

				columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
				swiperContainer.appendChild(columnBlock);

				swiperButtonPrev.setAttribute('class', 'swiper-button-prev');
				swiperButtonNext.setAttribute('class', 'swiper-button-next');
				swiperContainer.parentElement.appendChild(swiperButtonNext);
				swiperContainer.parentElement.appendChild(swiperButtonPrev);

				swiperItem.forEach(si => {
					si.classList.add('swiper-slide');
					si.classList.remove('column');
					si.style.height = null;
				});

				new Swiper(el.querySelector('.swiper-container'), {
					slidesPerView: 'auto',
					allowTouchMove: true,
					keyboard: {
						enabled: true
					},
					spaceBetween: 48,
					slidesOffsetAfter: 0,
					navigation: {
						nextEl: swiperButtonNext,
						prevEl: swiperButtonPrev
					}
				});
			}
		});
	})();
}

// Slider block
var productSliders = document.querySelectorAll('.content-block.is-product-slider-block');
if (productSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		productSliders.forEach(el => {
			if (el.querySelectorAll('.column.is-product').length > 1) {
				const columnBlock = el.querySelector('.columns.products'),
					swiperItem = columnBlock.querySelectorAll('.column.is-product'),
					swiperContainer = document.createElement('div'),
					swiperButtonPrev = document.createElement('div'),
					swiperButtonNext = document.createElement('div');

				swiperContainer.setAttribute('class', 'swiper-container products');
				columnBlock.setAttribute('class', 'swiper-wrapper');

				columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
				swiperContainer.appendChild(columnBlock);

				swiperButtonPrev.setAttribute('class', 'swiper-button-prev');
				swiperButtonNext.setAttribute('class', 'swiper-button-next');
				swiperContainer.parentElement.appendChild(swiperButtonNext);
				swiperContainer.parentElement.appendChild(swiperButtonPrev);

				swiperItem.forEach(si => {
					si.classList.add('swiper-slide');
					si.classList.remove('column');
					si.style.height = null;
				});

				new Swiper(el.querySelector('.swiper-container'), {
					slidesPerView: 'auto',
					allowTouchMove: true,
					keyboard: {
						enabled: true
					},
					spaceBetween: 48,
					slidesOffsetAfter: 0,
					navigation: {
						nextEl: swiperButtonNext,
						prevEl: swiperButtonPrev
					}
				});
			}
		});
	})();
}

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('nav'), navigation = document.querySelector('.nav'), navigationContainer = document.querySelector('.nav');
if (navigationToggler && navigation && navigationContainer) {

	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			//focusLock.on(navigationContainer);
			document.documentElement.classList.add('has-no-overflow');
		} else {
			//focusLock.off(navigationContainer);
			document.documentElement.classList.remove('has-no-overflow');
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Dropdown
const foundDropdowns = document.querySelectorAll('.dropdown-checkbox');
addEventToDropdowns(foundDropdowns);
function addEventToDropdowns(dropdowns) {
	if (dropdowns.length) {
		var activeDropdown = null;
		var activeOption = null;

		var onDropdownChange = function (el) {
			if (activeDropdown !== null) {
				activeDropdown.checked = false;
			}
			if (el === activeDropdown) {
				activeDropdown = null;
			}
			else {
				activeDropdown = el;
			}
		};

		var updateLabel = function (option, label, associatedDropdown) {
			if (activeOption === option) {
				setTimeout(function () { option.checked = false; }, 50);
				activeOption = null;
				label.innerHTML = label.dataset.placeholder;
				associatedDropdown.classList.remove('has-value');
			}
			else {
				activeOption = option;
				label.innerText = option.value;
				associatedDropdown.classList.add('has-value');
			}
		};

		dropdowns.forEach(el => {
			el.addEventListener("change", async function (e) {
				onDropdownChange(el);
			});
		});

		document.addEventListener('click', e => {
			if (e.target.closest('.dropdown-checkbox') === null && e.target.closest('.dropdown') === null) {
				if (activeDropdown !== null) {
					onDropdownChange(activeDropdown);
				}
			}
		});
	}
}

const dropdownContents = document.querySelectorAll('.dropdown__content');
if (dropdownContents.length) {
	dropdownContents.forEach(el => {
		window.addEventListener("load", function () {
			setTimeout(function () { el.parentElement.style.height = el.offsetHeight + "px"; }, 200);
		});
		window.addEventListener("resize", function () {
			setTimeout(function () { el.parentElement.style.height = el.offsetHeight + "px"; }, 200);
		});
	});
}

// Lazy load video
var lazyVideos = document.querySelectorAll('.video.lazy');
if (lazyVideos.length) {
	if ("IntersectionObserver" in window) {
		var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (var source in video.target.children) {
						var videoSource = video.target.children[source];
						if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove("lazy");
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	}
}

// Jump to submitted form
document.querySelectorAll('.content-block.is-form-block').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	if (form && button) {

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});

		var anchor = el.querySelector('.form').getAttribute('id');
		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);
	}
});

// Modal overview
var modalContainers = document.querySelectorAll('.modal-overview');
if (modalContainers.length) {

	modalContainers.forEach(el => {
		var culture = document.documentElement.lang;

		el.addEventListener('click', event => {
			var modalItem = event.target.closest('.modal-item');
			if (modalItem) {
				getModal('/umbraco/Surface/Modal/Info?id=' + modalItem.id + '&culture=' + culture, el);
			}
		});
	});
}

// Request sample 
var requestSampleButtons = document.querySelectorAll('.request-a-sample');
if (requestSampleButtons.length) {

	requestSampleButtons.forEach(el => {

		el.addEventListener('click', event => {
			var formId = el.dataset.formid;
			var formSection = document.getElementById(formId);
			var form = formSection.querySelector('.modal').cloneNode(true)
			var radioButtons = form.querySelectorAll('span.radio, .dataconsent');
			radioButtons.forEach(rb => {
				var label = rb.querySelector('label');
				var input = rb.querySelector('input');
				var labelFor = label.getAttribute("for");
				label.setAttribute("for", labelFor += "1");
				input.id += "1";
			});
			createModal(form, null);
		});
	});
}

function getModal(fetchUrl, overview) {

	fetch(fetchUrl, {
		method: 'get',
		headers: {
			'X-Requested-With': 'XMLHttpRequest'
		}
	})
		.then(response => response.text())
		.then(data => {
			var parser = new DOMParser();
			var newModal = parser.parseFromString(data, 'text/html').querySelector('.modal');
			createModal(newModal, overview);
		});
}

function createModal(newModal, overview) {

	var modalContainer = document.querySelector('.modal-container');

	if (modalContainer) {
		var oldModal = modalContainer.querySelector('.modal');
		oldModal.classList.remove('fade-in');
		afterModalAppend(modalContainer)
		setTimeout(function () { oldModal.remove(); modalContainer.appendChild(newModal); afterModalAppend(modalContainer); }, 500);
		setTimeout(function () { newModal.classList.add('fade-in'); }, 550);
	}
	else {
		modalContainer = document.createElement('div');
		modalContainer.classList.add('modal-container');
		document.body.appendChild(modalContainer);
		modalContainer.appendChild(newModal);

		setTimeout(function () { modalContainer.classList.add('fade-in'); }, 50);
		setTimeout(function () { newModal.classList.add('fade-in'); afterModalAppend(modalContainer); }, 500);

		modalContainer.addEventListener('click', event => {
			if (event.target === modalContainer) {
				closeModal(event, modalContainer);
			}
			if (event.target.className === 'modal__close') {
				closeModal(event, modalContainer);
			}
			if (event.target.className === 'modal__prev') {
				getSiblingModal(event, modalContainer, overview, false);
			}
			if (event.target.className === 'modal__next') {
				getSiblingModal(event, modalContainer, overview, true);
			}
			if (event.target.className === 'swiper-button-prev' || event.target.className === 'swiper-button-next') {
				getSiblingModal(event, modalContainer, overview, true);
			}
		});

		document.addEventListener('keydown', event => {
			if (event.keyCode === 27) {
				closeModal(event, modalContainer);
			}
		});
	}
}

function closeModal(e, modalContainer) {
	var modal = modalContainer.querySelector('.modal');
	modal.classList.remove('fade-in');
	setTimeout(function () { modalContainer.classList.remove('fade-in') }, 500);
	setTimeout(function () { modalContainer.remove(); }, 1000);
}

function getSiblingModal(e, modalContainer, overview, getNext) {
	var modal = modalContainer.querySelector('.modal');
	var id = modal.dataset.modalId;
	var modalItems = overview.querySelectorAll('.modal-item');
	var modalItemsCount = modalItems.length;
	var currentModelItemIndex = 0;
	var nextModalItemToShow = null;

	for (var i = 0; i < modalItemsCount; i++) {
		if (modalItems[i].id === id) {
			currentModelItemIndex = i;
		}
	}

	if (getNext) {
		nextModalItemToShow = modalItems[currentModelItemIndex + 1];
		if (!nextModalItemToShow) {
			nextModalItemToShow = modalItems[0];
		}
	}
	else {
		nextModalItemToShow = modalItems[currentModelItemIndex - 1];
		if (!nextModalItemToShow) {
			nextModalItemToShow = modalItems[(modalItemsCount - 1)];
		}
	}

	if (nextModalItemToShow !== null) {
		getModal('/umbraco/Surface/Modal/Info?id=' + nextModalItemToShow.id + '&culture=' + document.documentElement.lang, overview);
	}
}

function afterModalAppend(modelContainer) {
	var dropdownInModals = modelContainer.querySelectorAll('.dropdown-checkbox');
	addEventToDropdowns(dropdownInModals);
}